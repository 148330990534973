/**
 * Language selector component.
 * @module components/LanguageSelector/LanguageSelector
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import cx from 'classnames';
import { find, map } from 'lodash';

import {
  Helmet,
  langmap,
  flattenToAppURL,
  toReactIntlLang,
} from '@plone/volto/helpers';

import config from '@plone/volto/registry';
import icons_country_flags from '@plone/volto/registry';

import { defineMessages, useIntl } from 'react-intl';

import { Api } from '@plone/volto/helpers';

const messages = defineMessages({
  switchLanguageTo: {
    id: 'Switch to',
    defaultMessage: 'Switch to',
  },
});

const LanguageSelector = (props) => {
  const intl = useIntl();
  const currentLang = useSelector((state) => state.intl.locale);
  const translations = useSelector(
    (state) => state.content.data?.['@components']?.translations?.items,
  );

  const { settings } = config;

  // const [images, setImages] = useState({});

  // useEffect(() => {
  //   const fetchCountryFlags = async () => {
  //     const api = new Api();
  //     try {
  //       const imagesAr = await Promise.all(
  //         settings.supportedLanguages.map(async (lang) => {

  //           // ++plone++country-flag-icons/de.svg

  //           const flag = (lang === "en" ? "gb" : lang)
  //           const response = await api.get(`++plone++country-flag-icons/${flag}.svg`);
  //           return { [lang]: response };
  //         })
  //       );

  //       const imagesObj = imagesAr.reduce((acc, curr) => {
  //         return { ...acc, ...curr };
  //       }, {});

  //       setImages(imagesObj);
  //     } catch (error) {
  //       console.log('Error fetching country flags:', error);
  //     }
  //   };

  //   fetchCountryFlags();
  // }, []);


  return settings.isMultilingual ? (
    <div className="language-selector">
      {map(settings.supportedLanguages, (lang) => {
        const translation = find(translations, { language: lang });
        return (
          <Link
            aria-label={`${intl.formatMessage(
              messages.switchLanguageTo,
            )} ${langmap[lang].nativeName.toLowerCase()}`}
            className={cx({ selected: toReactIntlLang(lang) === currentLang })}
            to={translation ? flattenToAppURL(translation['@id']) : `/${lang}`}
            title={langmap[lang].nativeName}
            onClick={() => {
              props.onClickAction();
            }}
            key={`language-selector-${lang}`}
          >
              <span className="icon" style={{ backgroundImage: `url(++api++/++plone++country-flag-icons/${lang === "en" ? "gb" : lang}.svg)` }}></span>
            
            {/* {langmap[lang].nativeName} */}
          </Link>
        );
      })}
    </div>
  ) : (
    <Helmet>
      <html lang={toReactIntlLang(settings.defaultLanguage)} />
    </Helmet>
  );
};

LanguageSelector.propTypes = {
  onClickAction: PropTypes.func,
};

LanguageSelector.defaultProps = {
  onClickAction: () => { },
};

export default LanguageSelector;
